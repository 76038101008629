import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Outlet, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Stack, IconButton } from '@mui/material';

import Iconify from '../../components/Iconify';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { isUserAuthenticated } from '../../utils/user';
import CommonLayout from '../common';
import AccountPopover from './AccountPopover';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  width: '100%',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  // flexGrow: 1,
  overflow: 'auto',
  height: '100%',
  width: '100%',
  paddingTop: APP_BAR_MOBILE + 200,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 100,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkLogin = async () => {
      const isAuthenticated = await isUserAuthenticated();
      if (!isAuthenticated) {
        Auth.signOut();
        localStorage.removeItem('user');
        navigate('/login');
      }
    };

    checkLogin();
  });

  return (
    <CommonLayout
      headerLeft={
        <IconButton onClick={() => setOpen(true)} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      }
      headerRight={<AccountPopover />}
    >
      <RootStyle>
        <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        <MainStyle>
          <Stack marginTop={5}>
            <Outlet />
          </Stack>
        </MainStyle>
      </RootStyle>
    </CommonLayout>
  );
}
