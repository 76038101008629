import { styled } from '@mui/material/styles';
import { AppBar } from '@mui/material';
import LogoMarkWhite from './renesas_logomark_white.png';
import RA from './ra.jpg';
import RX from './rx.jpg';
import Hands from './hands.jpeg';
import HandsPlaceholder from './placeholder-hands.png';

const TopLine = styled(AppBar)(({ theme }) => ({
  height: 100,
  width: '100%',
  backgroundColor: theme.palette.primary.dark,
  display: 'flex',
  paddingLeft: 25,
  justifyContent: 'center',
  zIndex: 1400,
}));

const IconsLine = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  width: '100%',
  justifyContent: 'center',
  marginTop: 100,
  backgroundColor: '#fff',
  [theme.breakpoints.down('sm')]: {
    top: 0,
  },
}));

const IconsContainer = styled('div')(() => ({
  display: 'flex',
  height: 130,
  alignItems: 'center',
}));

const Logo = styled('img')(() => ({
  width: 300,
  height: 50,
}));

const RAImg = styled('img')(({ theme }) => ({
  width: 130,
  height: 50,
  [theme.breakpoints.down('sm')]: {
    width: 100,
    height: 40,
  },
}));

const RXImg = styled('img')(({ theme }) => ({
  width: 130,
  height: 40,
  marginTop: 8,
  [theme.breakpoints.down('sm')]: {
    width: 100,
    height: 40,
  },
}));

const HandsImg = styled('img')(({ theme }) => ({
  width: 130,
  height: 130,
  marginLeft: 50,
  marginRight: 50,
  [theme.breakpoints.down('sm')]: {
    width: 100,
    height: 100,
    marginLeft: 30,
    marginRight: 30,
  },
}));

const RightHeader = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: 50,
  top: 40,
  [theme.breakpoints.down('sm')]: {
    top: 120,
    right: 20,
  },
}));

const LeftHeader = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 50,
  top: 40,
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    top: 120,
    left: 20,
  },
}));

export default function CommonLayout({ children, headerLeft, headerRight }) {
  return (
    <>
      <TopLine>
        <Logo src={LogoMarkWhite} alt="Logo" />
      </TopLine>
      <IconsLine>
        {headerLeft && <LeftHeader>{headerLeft}</LeftHeader>}
        <IconsContainer>
          <RAImg src={RA} alt="" />
          <HandsImg src={Hands} alt="" placeholder={HandsPlaceholder} />
          <RXImg src={RX} alt="" />
        </IconsContainer>
        {headerRight && <RightHeader>{headerRight}</RightHeader>}
      </IconsLine>
      {children}
    </>
  );
}
