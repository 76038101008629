import React, { useEffect, useState } from 'react';
import { Grid, Container, Typography, CircularProgress } from '@mui/material';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';
// components
import Page from '../../components/Page';
import { getDevice } from '../../utils/api';
import { setUser } from '../../store/user/userSlice';
import { setDevice } from '../../store/device/deviceSlice';

import { DeviceAccordion, DeviceRegisterForm } from './components';

export default function DevicesList() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state) => state.user);
  const device = useSelector((state) => state.device);

  const userRegistered = !!user?.sn;

  const [loading, setLoading] = useState(false);

  useQuery(
    'registeredDevice',
    () => {
      if (userRegistered && !user?.provisioned && !device?.provisioned) {
        onGetDevice();
      } else if (device?.provisioned && !user?.provisioned) {
        Auth.currentAuthenticatedUser()
          .then((user) => Auth.updateUserAttributes(user, { 'custom:provisioned': 'provisioned' }))
          .then(() => {
            const newUser = { ...user, provisioned: 'provisioned' };
            dispatch(setUser(newUser));
            localStorage.setItem('user', JSON.stringify(newUser));
          })
          .catch((err) =>
            enqueueSnackbar(err.message, {
              variant: 'error',
            })
          )
          .finally(() => setLoading(false));
      } else {
        setLoading(false);
      }
    },
    { staleTime: 10000 }
  );

  const onDeviceCreated = (createdDevice) => {
    dispatch(setDevice(createdDevice));
  };

  const onGetDevice = () => {
    setLoading(true);
    getDevice({ sn: user?.sn })
      .then((res) => {
        dispatch(setDevice(res?.data));
      })
      .catch((err) => {
        if (err?.response?.status === 404)
          enqueueSnackbar('Your account is broken. Please contact admin.', {
            variant: 'error',
          });
        else
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (userRegistered && user?.provisioned) {
      onGetDevice();
    }
  }, []);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {userRegistered ? (
              <DeviceAccordion />
            ) : (
              <Grid container spacing={2} direction="column" justify="center">
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="h4" sx={{ mb: 5 }}>
                    Register Device
                  </Typography>
                  <DeviceRegisterForm onDeviceCreated={onDeviceCreated} />
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Container>
    </Page>
  );
}
