// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'My Devices',
    path: '/dashboard/devices-list',
    icon: getIcon('eva:file-text-fill'),
  },
];

export default navConfig;
