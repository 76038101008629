/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_oIuAuCPiM',
  aws_user_pools_web_client_id: '60ln4m33urccs2c1h53kuivubk',
  oauth: {
    scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'profile'],
    redirectSignIn: 'http://localhost',
    redirectSignOut: 'http://localhost',
    responseType: 'token',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: ['REQUIRES_LOWERCASE', 'REQUIRES_UPPERCASE', 'REQUIRES_NUMBERS', 'REQUIRES_SYMBOLS'],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_oIuAuCPiM',
    userPoolWebClientId: '60ln4m33urccs2c1h53kuivubk',
    identityPoolId: 'us-east-1:25033456-8c41-43b5-a968-1021fc945ecd',
  },
};

export default awsmobile;
