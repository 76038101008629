import { createSlice } from '@reduxjs/toolkit';

const getInitialState = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    sn: user?.sn || '',
    provisioned: user?.provisioned || '',
  };
};

export const userSlice = createSlice({
  name: 'user',
  initialState: getInitialState(),
  reducers: {
    setUser: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
