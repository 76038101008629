import * as Yup from 'yup';
import { Auth } from 'aws-amplify';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

export default function RestoreForm({ onCodeSend }) {
  const { enqueueSnackbar } = useSnackbar();

  const RestoreSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email address')
      .min(6, 'Too Short!')
      .max(64, 'Too Long!')
      .required('Email is required')
      .matches(/^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, 'Email must be a valid email address'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: RestoreSchema,
    onSubmit: ({ email }, { setSubmitting }) => {
      Auth.forgotPassword(email)
        .then(() => {
          setSubmitting(false);

          enqueueSnackbar('Verification code was sent to your email', {
            variant: 'success',
          });

          onCodeSend(email);
        })
        .catch((err) => {
          setSubmitting(false);
          enqueueSnackbar(err.message, {
            variant: 'error',
          });
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Typography sx={{ color: 'text.secondary', mb: 5 }}>Enter your email.</Typography>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} sx={{ my: 2 }}>
          Send
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
