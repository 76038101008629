import { styled } from '@mui/material/styles';
import Cloud from './cloud.jpeg';

const CloudImg = styled('img')(({ theme }) => ({
  width: 100,
  height: 90,
  cursor: 'pointer',
  margin: 20,
  zIndex: 100,
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    alignSelf: 'center',
  },
}));

export default function CloudIcon() {
  const onClick = () => {
    window.open('https://renesas.com/cloudsolutions');
  };
  return <CloudImg src={Cloud} alt="" onClick={onClick} />;
}
