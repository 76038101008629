import { Grid, Container, Typography, Stack, Button, Radio, RadioGroup ,FormControlLabel, FormLabel,FormControl } from '@mui/material';
import { useSelector } from 'react-redux';
import { useState } from 'react';

import { creditCardchange } from '../../utils/api';
import Page from '../../components/Page';

import { ProfileForm, ChangePasswordForm } from './components';

export default function Profile() {
 
  const user = useSelector((state) => state.user);
  const device = useSelector((state)=>state.device);
  const [changePass, setChangePass] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [paymentPreference, setPaymentPreference] = useState("no");
  const serialNumber = user?.sn?.toLowerCase();

  const handleChangePass = () => {
    setChangePass(!changePass);
  };

  const handleEditProfile = () => {
    setEditProfile(!editProfile);
  };

  const handleEditCCUpdate = () => {
    const userPref = {
      sn: serialNumber,
      choice: paymentPreference
    }
    creditCardchange(userPref);
  
  };
  const handleChange = (preference) => {
    setPaymentPreference(preference);
  };
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Grid container spacing={2} direction="column" justify="center">
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h4" sx={{ mb: 5 }}>
              Profile Page
            </Typography>
            {editProfile ? (
              <ProfileForm handleEditProfile={handleEditProfile} />
            ) : (
              <Stack>
                <Typography variant="h6" gutterBottom>
                  First name:
                  <Typography gutterBottom>{user?.firstName}</Typography>
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Last name:
                  <Typography gutterBottom>{user?.lastName}</Typography>
                </Typography>

                {changePass ? (
                  <ChangePasswordForm handleChangePass={handleChangePass} />
                ) : (
                  <>
                    <Button variant="contained" sx={{ width: 350, my: 1 }} onClick={handleEditProfile}>
                      Edit Profile
                    </Button>
                    <Button variant="contained" sx={{ width: 350, my:1  }} onClick={handleChangePass}>
                      Change Password
                    </Button>
                  </>
                )}
                <Stack direction="row">
                <Button
                  variant="contained"
                  sx={{ width: 350, my: 1 }} // Adjust margin as needed
                  onClick={handleEditCCUpdate}
                >
                  Payment Preference update Confirmation
                </Button>
                <FormControl sx={{m: 1}} >
                  <FormLabel id="demo-controlled-radio-buttons-group"> </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={paymentPreference}
                    onChange ={(e) => {
                      handleChange(e.target.defaultValue);
                      }}
                  >
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Stack>
              </Stack>
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
