import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
import Page from '../../components/Page';
import CloudIcon from '../../components/CloudIcon';
import useResponsive from '../../hooks/useResponsive';
import { ConfirmNewPasswordForm, RestoreForm } from './components';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  marginTop: 70,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    marginTop: 250,
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  height: '60vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function RestorePassword() {
  const [email, setEmail] = useState(false);
  const smUp = useResponsive('up', 'sm');

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>{smUp && <CloudIcon />}</HeaderStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              Restore Password
            </Typography>

            {!!email ? <ConfirmNewPasswordForm email={email} /> : <RestoreForm onCodeSend={setEmail} />}
            {!smUp && <CloudIcon />}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
