import { styled } from '@mui/material/styles';
import Logo from './logo.jpg';

const LogoImg = styled('img')(() => ({
  width: 200,
  cursor: 'pointer',
  margin: 20,
}));

export default function LogoIcon() {
  return <LogoImg src={Logo} alt="" />;
}
