import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';
import RestorePassword from './pages/Auth/RestorePassword';

import DevicesList from './pages/Devices/DevicesList';
import DeviceGrafana from './pages/Devices/DeviceGrafana';

import NotFound from './pages/ErrorPages/Page404';
import Profile from './pages/Profile/Profile';
import SupportPage from './pages/SupportPage/SupportPage';
import NginxError from './pages/ErrorPages/NginxError';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'profile', element: <Profile /> },
        { path: 'devices-list', element: <DevicesList /> },
        { path: 'device-grafana', element: <DeviceGrafana /> },
        { path: 'support-page', element: <SupportPage /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/devices-list" /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'restore-password', element: <RestorePassword /> },
        { path: '404', element: <NotFound /> },
        { path: 'nginx-error', element: <NginxError /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
