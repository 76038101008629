import { createSlice } from '@reduxjs/toolkit';

export const deviceSlice = createSlice({
  name: 'device',
  initialState: {
    username: '',
    email: '',
    serialNumber: '',
    provisioned: false,
    preventRequest: false,
  },
  reducers: {
    setDevice: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export const { setDevice } = deviceSlice.actions;

export default deviceSlice.reducer;
