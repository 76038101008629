import axios from 'axios';
import { Auth } from 'aws-amplify';
import { Alert } from '@mui/material'

const axiosInstance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=UTF-8',
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      Auth.currentAuthenticatedUser().then((cognitoUser) => {
        Auth.currentSession().then((currentSession) => {
          cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
            const idToken = session?.idToken?.jwtToken;
            localStorage.setItem('token', idToken);

            const { config } = error;
            config.headers.Authorization = idToken;
            return axios.request(config);
          });
        });
      });
    }
    return Promise.reject(error);
  }
);

const baseURL = process.env.REACT_APP_API_URL;

export const getDevice = ({ sn }) => {
  const token = localStorage.getItem('token');
  return axiosInstance.get(`${baseURL}/get?sn=${sn}`, {
    headers: {
      Authorization: token,
    },
  });
};

export const registerDevice = (device) => {
  const token = localStorage.getItem('token');
  return axiosInstance.post(`${baseURL}/start`, device, {
    headers: { Authorization: token },
  });
};

export const creditCardchange = (prefdata) => {
  const token = localStorage.getItem('token');
  return axiosInstance.post(`${baseURL}/updatecc`, prefdata, {
    headers: {Authorization: token}
  })
  .then(response => {
    // Check if the response status is 200 (success)
    if (response.status === 200) {
      // Show a success message
      alert('Your confirmation of updating payment preference information was successful');
    } else {
      // Show a failure message for other status codes
      alert('Failed to confirm your update of payment preference information. Please try again.');
    }
    return response; // Pass the response along
  })
  .catch(error => {
    // Handle errors if the request fails
    // Show a failure message
    alert('Contact Renesas Support');
    throw error; // Rethrow the error for further handling if needed
  });
};  


export const downloadCert = (sn) => {
  const token = localStorage.getItem('token');
  return axiosInstance.post(
    `${baseURL}/get-download-url`,
    { sn },
    {
      headers: { Authorization: token },
    }
  );
};

export const checkbudget = (email) => {
  const token = localStorage.getItem('token');
  return axiosInstance.post(
    `${baseURL}/checkbudget`,
    { email },
    {
      headers: { Authorization: token },
    }
  )
  .then(response => {
    // Check if the response status is 200 (success)
    console.log(response);
    if (response.status === 200) {
      // Show a success message
      console.log("Account usage above 80 %");
      alert('You have exceeded 80% of Credit usage. Update your Payment preferences to have unrestricted access to all resources');
    } else {
      // Show a failure message for other status codes
      console.log("Account usage under 80 %");
    }
    return response; // Pass the response along
  })
/*   .then((data) => {
    const usageValue = data.Usage;
    console.log(usageValue)
    // alert('Usage value: ' + usageValue);
  });  */
};

export const resetGrafanaPassword = ({ sn }) => {
  const token = localStorage.getItem('token');
  return axiosInstance.post(`${baseURL}/reset?sn=${sn}`, null, {
    headers: {
      Authorization: token,
    },
  });
};
