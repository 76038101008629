import { Auth } from 'aws-amplify';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { setUser } from '../store/user/userSlice';

export const signIn = ({ email, password, dispatch, navigate }) =>
  Auth.signIn({
    username: email,
    password,
    attributes: {
      email,
    },
  }).then(async (res) => {
    await Auth.currentSession().then((res) => {
      const idToken = res.getIdToken();
      localStorage.setItem('token', idToken?.jwtToken);
    });
    const user = {
      firstName: res?.attributes?.['custom:firstName'],
      lastName: res?.attributes?.['custom:lastName'],
      sn: res?.attributes?.['custom:sn']?.toLowerCase(),
      provisioned: res?.attributes?.['custom:provisioned'],
      email: res?.attributes?.email,
    };

    localStorage.setItem('user', JSON.stringify(user));
    dispatch(setUser(user));
    navigate('/dashboard/devices-list', { replace: true });

    // serviceProvider

    Auth.currentCredentials().then((credentials) => {
      const cognitoServiceProvider = new CognitoIdentityServiceProvider({
        region: process.env.REACT_APP_AWS_REGION,
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
      });

      const params = {
        AuthFlow: 'USER_PASSWORD_AUTH',
        ClientId: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID,
        AuthParameters: {
          USERNAME: email,
          PASSWORD: password,
        },
        AnalyticsMetadata: {
          AnalyticsEndpointId: user.email,
        },
      };

      cognitoServiceProvider.initiateAuth(params, (err, data) => {
        if (err) console.log(err, err.stack, 'cognito error');
        else {
          console.log(data, 'cognito data');
        }
      });
    });
  });
