import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { alpha, useTheme } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, IconButton } from '@mui/material';
import MenuPopover from '../../components/MenuPopover';
import Iconify from '../../components/Iconify';
import { resetGrafanaPassword } from '../../utils/api';

const MENU_OPTIONS = [
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    linkTo: 'profile',
    type: 'link',
  },
  {
    label: 'Support Page',
    icon: 'bx:link',
    linkTo: 'support-page',
    type: 'link',
  },
  {
    label: "Reset Grafana's password",
    icon: 'bx:link',
    type: 'button',
    resetGrafanaPassword: true,
  },
];

export default function AccountPopover() {
  const { enqueueSnackbar } = useSnackbar();
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const theme = useTheme();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (option) => {
    if (option?.resetGrafanaPassword) {
      if (user?.sn) {
        enqueueSnackbar('Password change request sent successfully', { variant: 'success' });
        resetGrafanaPassword({ sn: user?.sn })
          .then(() => {
            enqueueSnackbar('Password reset successfully', { variant: 'success' });
          })
          .catch((e) => {
            enqueueSnackbar(e?.response?.data?.message, {
              variant: 'error',
            });
          });
      } else {
        enqueueSnackbar("You haven't registered device yet", { variant: 'warning' });
      }
    }
    setOpen(null);
  };

  const handleLogOut = () => {
    handleClose();
    Auth.signOut();
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey.dark, 0.8),
            },
          }),
        }}
      >
        <Iconify icon="bxs:user-circle" sx={{ fontSize: 50 }} color={theme.palette.primary.dark} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {`${user?.firstName} ${user?.lastName}`}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option?.linkTo}
              component={option?.type === 'link' && RouterLink}
              onClick={() => handleClose(option)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogOut} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
