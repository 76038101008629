// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none',
          },
          // backgroundColor: theme.palette.primary.darker,
        },
        sizeLarge: {
          height: 48,
        },
        containedInherit: {
          color: theme.palette.grey.dark,
          boxShadow: theme.customShadows.z8,
          backgroundColor: theme.palette.primary.darker,
          '&:hover': {
            backgroundColor: theme.palette.grey.light,
          },
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary,
          backgroundColor: theme.palette.primary.darker,
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
          backgroundColor: theme.palette.primary.darker,
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey.light}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
          color: theme.palette.primary.darker,
        },
      },
    },
  };
}
