import { Typography, Button, Stack, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import { useEffect } from 'react';
import Iconify from '../../../components/Iconify';
import { downloadCert, checkbudget } from '../../../utils/api';

export default function DeviceAccordion() {


  
 


  
  const { enqueueSnackbar } = useSnackbar();
  const device = useSelector((state) => state.device);
  const email=device?.email
  const handleDownloadCert = (sn) => {
    downloadCert(sn)
      .then((res) => {
        window.open(res?.data);
      })
      .catch((e) => {
        enqueueSnackbar(e?.response?.data?.message, {
          variant: 'error',
        });
      });
  };

  const onClickGrafana = (sn) => {
    if (sn) window.open(`${process.env.REACT_APP_DASHBOARD_URL}/${sn}`);
  };

  const serialNumber = device?.serialNumber?.toLowerCase();
  const provisioned = device?.provisioned;
  const deviceStatus = device?.status;

  const getStatus = (statusKey, provisioned_) => {
    switch (statusKey) {
      case 'AVAILABLE':
        if (provisioned_) return { title: 'Provisioned', color: 'green' };
        else return { title: 'Under Provisioning', color: 'orange' };
      case 'quarantine':
        return { title: 'Quarantined', color: 'red' };
      case 'ERROR':
        return { title: 'Error', color: 'red' };
      default:
        return { title: 'In Progress', color: 'orange' };
    }
  };

  const status = getStatus(deviceStatus, provisioned);
  const disableButtons = !provisioned || deviceStatus === 'quarantine';
  useEffect(() => {
    console.log(device?.email);
    checkbudget(device?.email);
      // Your function to trigger after the full page is loaded
      console.log('Page is fully loaded!');
      // Call your function here
      // functionName();
      
  }, [])


  return (
    <Accordion>
      <AccordionSummary
        sx={{
          boxShadow: 0,
          borderRadius: 1,
          color: (theme) => theme.palette.primary.darker,
          bgcolor: (theme) => theme.palette.primary.lighter,
          cursor: 'pointer',
        }}
        expandIcon={<Iconify icon="flat-color-icons:expand" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="subtitle1" sx={{ width: '75%', flexShrink: 0 }}>
          Device 1
        </Typography>
        <Typography variant="subtitle1" sx={{ display: 'flex' }}>
          Status:
          <Typography variant="subtitle1" sx={{ marginLeft: 1, color: status?.color }}>
            {status?.title}
          </Typography>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {deviceStatus === 'ERROR' && (
          <Typography variant="h6" gutterBottom sx={{ color: 'red' }}>
            Error:
            <Typography gutterBottom>{device?.status_message}</Typography>
          </Typography>
        )}
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
          <Stack>
            <Typography variant="h6" gutterBottom>
              Account name:
              <Typography gutterBottom>{device?.username}</Typography>
            </Typography>
            <Typography variant="h6" gutterBottom>
              Email:
              <Typography gutterBottom>{device?.email}</Typography>
            </Typography>
            <Typography variant="h6" gutterBottom>
              UUID:<Typography gutterBottom>{serialNumber}</Typography>
            </Typography>
          </Stack>

          <Stack>
            <Button
              variant="contained"
              disabled={disableButtons}
              onClick={() => handleDownloadCert(serialNumber)}
              startIcon={<Iconify icon="eva:download-outline" />}
            >
              Download certificate
            </Button>
            <Button
              sx={{ my: 1 }}
              disabled={disableButtons}
              variant="contained"
              onClick={() => onClickGrafana(serialNumber)}
              endIcon={<Iconify icon="akar-icons:arrow-right" />}
            >
              Go to Dashboard
            </Button>
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
