import 'simplebar/src/simplebar.css';
import './fonts/PublicSans-Regular.ttf';

import { Amplify, Analytics, Auth } from 'aws-amplify';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import App from './App';

import awsconfig from './aws-exports';
import analyticsConfig from './configs/analyticsConfig';
import amplifyConfig from './configs/amplifyConfig';

Auth.configure(amplifyConfig);
Analytics.configure(analyticsConfig);
Amplify.configure(awsconfig);

ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById('root')
);
