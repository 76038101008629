import * as Yup from 'yup';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, Form, FormikProvider } from 'formik';

import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { registerDevice } from '../../../utils/api';
import { setUser } from '../../../store/user/userSlice';

// ----------------------------------------------------------------------

export default function DeviceRegisterForm({ onDeviceCreated }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const DeviceSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email address')
      .min(6, 'Too Short!')
      .max(64, 'Too Long!')
      .required('Email is required')
      .matches(/^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, 'Email must be a valid email address'),
    serialNumber: Yup.string()
      .required('UUID is required')
  });

  const formik = useFormik({
    initialValues: {
      email: user?.email || '',
      serialNumber: '',
    },
    validationSchema: DeviceSchema,
    onSubmit: (deviceInfo, { setSubmitting }) => {
      const serialNumber = deviceInfo?.serialNumber?.toLowerCase();
      const device = {
        sn: serialNumber,
        AccountEmail: deviceInfo?.email,
        UserLastName: user?.lastName,
        UserFirstName: user?.firstName,
      };
      registerDevice(device)
        .then((res) => {
          Auth.currentAuthenticatedUser()
            .then(async (cognitoUser) => {
              Auth.currentSession().then((currentSession) => {
                cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
                  const newUser = { ...user, sn: serialNumber };
                  dispatch(setUser(newUser));
                  localStorage.setItem('user', JSON.stringify(newUser));

                  const idToken = session?.idToken?.jwtToken;
                  localStorage.setItem('token', idToken);
                });
              });
            })
            .catch((err) =>
              enqueueSnackbar(err.message, {
                variant: 'error',
              })
            );

          onDeviceCreated({
            ...deviceInfo,
            serialNumber,
            provisioned: false,
            preventRequest: true,
            username: res?.data?.account_name,
          });
          enqueueSnackbar('Device registration started!', {
            variant: 'success',
          });
        })
        .catch((e) => {
          enqueueSnackbar(e?.response?.data, {
            variant: 'error',
          });
        })
        .finally(() => setSubmitting(false));
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  const onSNChange = (e) => {
    setFieldValue('serialNumber', e?.target?.value?.toLowerCase());
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="email"
            type="email"
            label="Email"
            disabled
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            label="UUID"
            {...getFieldProps('serialNumber')}
            onChange={onSNChange}
            error={Boolean(touched.serialNumber && errors.serialNumber)}
            helperText={touched.serialNumber && errors.serialNumber}
            inputProps={{ style: { textTransform: 'lowercase' } }}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Submit
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
