import * as Yup from 'yup';
import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { signIn } from '../../../utils/amplifyApi';

export default function ConfirmEmailForm({ user }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [disableButton, setDisableButton] = useState(false);

  const onResend = () => {
    Auth.resendSignUp(user?.email)
      .then(() => {
        setDisableButton(true);
        enqueueSnackbar('Code sent successfully!', {
          variant: 'success',
        });
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      });
  };

  const ConfirmEmailSchema = Yup.object().shape({
    verificatoinCode: Yup.string()
      .required('Verification code is required')
      .max(6, 'Verificatoin code must be at most 6 characters'),
  });

  const formik = useFormik({
    initialValues: {
      verificatoinCode: '',
    },
    validationSchema: ConfirmEmailSchema,
    onSubmit: ({ verificatoinCode }, { setSubmitting }) => {
      const { email, password } = user;
      Auth.confirmSignUp(email, verificatoinCode?.toString())
        .then(async () => {
          signIn({ email, password, dispatch, navigate }).catch((err) => {
            setSubmitting(false);
            enqueueSnackbar(err.message, {
              variant: 'error',
            });
            navigate('/login', { replace: true });
          });
        })
        .catch((err) => {
          setSubmitting(false);
          enqueueSnackbar(err.message, {
            variant: 'error',
          });
        });
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Confirm your email
      </Typography>
      <Typography sx={{ color: 'text.secondary', mb: 5 }}>
        The verification code has been sent to you, please check your email.
      </Typography>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              type="number"
              label="Verification code"
              {...getFieldProps('verificatoinCode')}
              error={Boolean(touched.verificatoinCode && errors.verificatoinCode)}
              helperText={touched.verificatoinCode && errors.verificatoinCode}
            />
            <Stack direction="row" alignItems="center" justifyContent="flex-end">
              <Button disabled={disableButton} variant="text" onClick={onResend}>
                Resend Code
              </Button>
            </Stack>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
              Send
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
